import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box, Button, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
  Tooltip, IconButton, Pagination, CircularProgress, TextField, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description';
import { styled } from '@mui/system';
import axios from 'axios';
import { useSelector } from 'react-redux';



const TableHeaderCell = styled(TableCell)({
  backgroundColor: '#0056b3',
  color: '#fff',
  fontWeight: 'bold',
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#f5f5f5',
    cursor: 'pointer',
  },
}));

const SearchStudent = () => {
  const [students, setStudents] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [studentToDelete, setStudentToDelete] = useState(null);
  const studentsPerPage = 10;
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);

  


  useEffect(() => {
    setLoading(true);
    setTimeout(() => {

      fetchStudentData()
    }, 1000);
  }, []);

  const fetchStudentData = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/students/getAll`,{
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      if (response.data) {
        setStudents(response.data);
      }
    } catch (error) {
      console.error("Error fetching the student data!", error)
    }
    setLoading(false);
  };

  const handleViewDetailsClick = (studentId) => {
    navigate(`/student/${studentId}`);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleDeleteClick = (studentId) => {
    setStudentToDelete(studentId);
    setOpenDialog(true);
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/students/${studentToDelete}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        }      
      })

      fetchStudentData()

    } catch (error) {
       alert('error deleting student')
    }
    setOpenDialog(false);
    setStudentToDelete(null);
  };

  const filteredStudents = students.filter((student) => {
    const searchLower = searchQuery.toLowerCase();
    return (
      student.firstName.toLowerCase().includes(searchLower) ||
      student.lastName.toLowerCase().includes(searchLower) ||
      student.studentId.toString().includes(searchLower)
    );
  });

  const currentStudents = filteredStudents.slice((page - 1) * studentsPerPage, page * studentsPerPage);

  return (
    <Box sx={{ width: '100%', backgroundImage: 'linear-gradient(180deg, #CEE5FD, #FFF)', py: 20 }}>
      <Container>
        <Typography variant="h4" sx={{ mb: 2, textAlign: 'center', color: '#0056b3' }}>
          Student List
        </Typography>

        <Typography variant="body1" sx={{ mb: 4, textAlign: 'center', color: '#333' }}>
          Below is a list of students enrolled in various courses. You can view full details or remove students from the list.
        </Typography>

        <TextField
          label="Search by Name or ID"
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ mb: 3 }}
        />

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <TableContainer sx={{ border: '1px solid #e0e0e0', borderRadius: '8px' }}>
              <Table sx={{ minWidth: 600 }}>
                <TableHead>
                  <TableRow>
                    <TableHeaderCell>Serial No.</TableHeaderCell>
                    <TableHeaderCell>Registration No</TableHeaderCell>
                    <TableHeaderCell>First Name</TableHeaderCell>
                    <TableHeaderCell>Last Name</TableHeaderCell>
                    <TableHeaderCell>Course</TableHeaderCell>
                    <TableHeaderCell>Actions</TableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredStudents.length > 0 ? (
                    currentStudents.map((student, index) => (
                      <StyledTableRow key={student.studentId} onClick={() => handleViewDetailsClick(student.studentId)}>
                        <TableCell>{(page - 1) * studentsPerPage + index + 1}</TableCell>
                        <TableCell>{student.studentId}</TableCell>
                        <TableCell>{student.firstName}</TableCell>
                        <TableCell>{student.lastName}</TableCell>
                        <TableCell>{student.course}</TableCell>
                        <TableCell>
                          <Tooltip title="View Full Details" arrow>
                            <IconButton color="primary" onClick={(e) => { e.stopPropagation(); handleViewDetailsClick(student.studentId); }}>
                              <DescriptionIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete Student" arrow>
                            <IconButton color="error" onClick={(e) => { e.stopPropagation(); handleDeleteClick(student.studentId); }}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        No Students Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Pagination
                count={Math.ceil(filteredStudents.length / studentsPerPage)}
                page={page}
                onChange={handlePageChange}
                color="primary"
              />
            </Box>
          </>
        )}
      </Container>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {studentToDelete?.firstName} {studentToDelete?.lastName} from the list? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SearchStudent;
