import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useTheme } from '@mui/material/styles';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import HomeRepairServiceRoundedIcon from '@mui/icons-material/HomeRepairServiceRounded';
import { HighlightsArray } from '../HeroArrays';
import DynamicHighlights from '../DisplayDynamicComponent/DynamicHighlights';
import FooterT from '../DownFooter';
import MapComponent from '../Map';

const genius = require('../../New/47f5e1d1-9216-4fff-9d84-65fb003a2d8a.jpeg')

const benefits = [
  {
    icon: <ThumbUpAltRoundedIcon fontSize="large" />,
    title: 'Best Training',
    description: 'Genius is giving 100% practical training for all.',
  },
  {
    icon: <VerifiedRoundedIcon fontSize="large" />,
    title: 'Best Trainers',
    description: 'We have Best Trainers with 10 years of Industry experience.',
  },
  {
    icon: <SupportAgentRoundedIcon fontSize="large" />,
    title: 'Advanced Learning',
    description: 'For the students we have High configuration systems.',
  },
  {
    icon: <HomeRepairServiceRoundedIcon fontSize="large" />,
    title: 'Lab Support',
    description: 'We are providing any time Lab Facility for the students.',
  },
];

const About = () => {
  const theme = useTheme();

  return (
    <Box
      id="hero"
      sx={{
        width: '100%',
        backgroundImage: 'linear-gradient(180deg, #CEE5FD, #FFF)',
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
        overflowX: 'hidden',
        position: 'relative',
      }}
    >
      <Container sx={{ py: { xs: 10, sm: 12 }, textAlign: 'center' }}>
        <Typography variant="h2" sx={{ fontSize: { xs: '2rem', sm: '2.5rem' }, mb: 3, mt: 4 }}>
          About Us
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mb: { xs: 3, sm: 6 }, px: { xs: 2, sm: 4 } }}>
          At Genius Institute of Management of India, we pride ourselves on being a hub of excellence and innovation in education. Our institute is dedicated to providing a comprehensive learning experience that prepares students for success in their chosen fields. Our commitment to quality education, practical training, and personalized support sets us apart as a leading institution in the industry.
        </Typography>

        <Grid container spacing={4}>
          {benefits.map(({ icon, title, description }, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  borderRadius: 2,
                  boxShadow: '#abcdef',
                  backgroundColor: '#A7C7E7',
                  p: 2,
                  border: `1px solid transparent`,
                  '&:hover': {
                    boxShadow:  '#abcdef',
                    transform: 'scale(1.05)',
                    transition: '0.3s',
                    borderColor: '#CEE5FD',
                  },
                }}
              >
                <Box sx={{ mb: 2 }}>{icon}</Box>
                <CardContent>
                  <Typography variant="h6" color="text.primary">
                    {title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Director's Message Section */}
      <Container sx={{ py: { xs: 4, sm: 6 }, textAlign: 'center' }}>
        <Typography variant="h3" sx={{ fontSize: { xs: '1.8rem', sm: '2.3rem' }, mb: 4 }}>
          Director’s Message
        </Typography>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={5}>
            <Box
              component="img"
              src={genius}
              alt="Director"
              sx={{
                width: '100%',
                height: 'auto',
                borderRadius: 2,
                boxShadow: '0 0 15px rgba(0,0,0,0.2)',
              }}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <Typography variant="body1" color="text.primary" sx={{ textAlign: 'justify', px: { xs: 2, sm: 4 } }}>
              Welcome to <span style={{ fontWeight: 'bold' }}>Genius Industrial Training Institute and Management</span>! Our vision is to empower individuals through
              industry-relevant education, fostering skills that lead to real-world success. At our institute, we focus on
              providing the best possible learning environment, where theory meets practice. We ensure that every student receives
              the support and resources they need to achieve excellence and make meaningful contributions to their fields.
            </Typography>

            <Typography variant="body1" color="text.primary" sx={{ mt: 2, textAlign: 'justify', px: { xs: 2, sm: 4 } }}>
              Our team of experienced trainers, state-of-the-art facilities, and industry-aligned curriculum are designed to give
              our students the tools they need to excel. We are committed to continuous improvement and innovation in education,
              striving to stay ahead of industry demands and technological advancements. Together, let’s build a future where
              learning knows no bounds and excellence becomes a way of life.
            </Typography>
            <Typography variant="h6" color="primary" sx={{ mt: 4, fontWeight: 'bold' }}>
              - Mr. Amir Shaikh, <span style={{ color: theme.palette.primary.main }}>Director of Genius Industrial Training Institute And Management</span>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      
      <DynamicHighlights
        title={'Highlights'}
        description={'Discover the key features and benefits of our institution. From top certifications to flexible timings and open admissions, explore what sets us apart.'}
        highlights={HighlightsArray}
      />
      <MapComponent />
      <FooterT />
    </Box>
  );
};

export default About;
