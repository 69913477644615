import ClassRoundedIcon from '@mui/icons-material/ClassRounded';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import EmojiPeopleRoundedIcon from '@mui/icons-material/EmojiPeopleRounded';
import WorkRoundedIcon from '@mui/icons-material/WorkRounded';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import CodeRoundedIcon from '@mui/icons-material/CodeRounded';
import DesignServicesRoundedIcon from '@mui/icons-material/DesignServicesRounded';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';
import PlacementIcon from '@mui/icons-material/WorkOutline';  // Placeholder icon
import PriceCheckIcon from '@mui/icons-material/PriceCheck';  // Placeholder icon
import ContactMailRoundedIcon from '@mui/icons-material/ContactMailRounded';  // New icon for Contact Us
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import SchoolIcon from '@mui/icons-material/School';
import PeopleIcon from '@mui/icons-material/People';
import ClassIcon from '@mui/icons-material/Class';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import RestaurantRoundedIcon from '@mui/icons-material/RestaurantRounded'; // Import the icon for hospitality


export const whyChooseUs = [
  {
    title: "Best Training",
    description: "Genius Institute is giving 100% practical training for all the students.",
    imageLight: require('../compresspng/train.png'),
    icon: <SchoolIcon />
  },
  {
    title: "Best Trainers",
    description: "We have Best Trainers with 10 years of Industry experience.",
    imageLight: require('../compresspng/traint.png'),

    icon: <PeopleIcon />
  },
  {
    title: "Advance Classroom",
    description: "Our classroom is well furnished with well labs facilities.",
    imageLight: require('../classImages/classes.png'),
    icon: <ClassIcon />
  },
  {
    title: "Low Cost Training",
    description: "Our fee is low, any one student can take admission easily.",
    imageLight: require('../classImages/placement1.png'),
    icon: <AttachMoneyIcon />
  }
];



export  const Facilities = [
    {
      icon: <ClassRoundedIcon />,
      title: 'Visual Based Classes',
      description: 'We have visual classes with well-equipped facilities.',
      imageLight: require('../classImages/y.jpg'),
     
    },
    {
      icon: <BusinessRoundedIcon />,
      title: 'Industrial Visit',
      description: 'Our team arranges industrial visits for all students.',
      imageLight: require('../exprincedtrainer/fieldvisit.jpeg'),
    },
    {
      icon: <EmojiPeopleRoundedIcon />,
      title: 'Experienced Trainers',
      description: 'Learn from experienced trainers who provide full support.',
      imageLight: require('../exprincedtrainer/exprincedtrainer.jpeg'),
    },
    {
      icon: <WorkRoundedIcon />,
      title: 'Placement Support',
      description: 'Full support in placement in India and abroad.',
      imageLight: require('../exprincedtrainer/placedmentsupport.jpeg'),
    },
  ];

 export  const courses = [
    {
      icon: <SchoolRoundedIcon />,
      title: 'Diploma in WATER TREATMENT / WATER UTILITY',
      description:
        'Learn the fundamentals of WATER TREATMENT / WATER UTILITY with our comprehensive course. Perfect for beginners and intermediates.',
      imageLight: require('../exprincedtrainer/watertr.jpeg'),
    },
    {
      icon: <CodeRoundedIcon />,
      title: 'Diploma in ELECTRICAL & ELECTRONIC',
      description:
        'Dive into ELECTRICAL & ELECTRONIC with hands-on projects and learn how to analyze electronics effectively.',
      imageLight: require('../exprincedtrainer/ele.png'),
    },
    {
      icon: <DesignServicesRoundedIcon />,
      title: 'Diploma in Civil',
      description:
        'Explore BUILDING MANAGEMENT SYSTEM (BMS) techniques and tools to create stunning structures.',
      imageLight: require('../exprincedtrainer/bmx.png'),
    },
    {
      icon: <RestaurantRoundedIcon />, // Icon for Hospitality
      title: 'Diploma in Hospitality',
      description:
        'Master the essentials of hospitality management, from customer service to event planning, in this comprehensive course.',
      imageLight: require('../exprincedtrainer/hos.avif'), // Replace with the correct path for the image
    },
  ];

export  const HighlightsArray = [
    {
      icon: <VerifiedRoundedIcon />,
      title: 'ISO Certified',
      description: 'We adhere to the highest international standards, ensuring top quality and reliability.',
    },
    {
      icon: <ScheduleRoundedIcon />,
      title: 'Class Timing',
      description: 'Flexible class timings to suit your schedule, providing you with convenience and accessibility.',
    },
    {
      icon: <SchoolRoundedIcon />,
      title: 'Admission Open',
      description: 'Join us today! Our admission process is open, and we are excited to welcome new students.',
    },
    {
      icon: <PlacementIcon />,  // Placeholder icon
      title: '100% Placement',
      description: 'We guarantee 100% placement assistance to help you start your career successfully.',
    },
    {
      icon: <PriceCheckIcon />,  // Placeholder icon
      title: 'Affordable Price',
      description: 'Our courses are priced competitively to ensure quality education is accessible to all.',
    },
    {
      icon: <ContactMailRoundedIcon />,  // New icon for Contact Us
      title: 'Contact Us',
      description: 'Email: gitimng@gmail.com | Phone: +91 8692848115',
    },
  ];
export   const VerificationHighlights = [
    {
      icon: <VerifiedRoundedIcon />,
      title: 'Secure Verification',
      description: 'Our verification process ensures that only authenticated students can access and download their certificates securely.',
    },
    {
      icon: <LockRoundedIcon />,
      title: 'Data Privacy',
      description: 'We maintain strict privacy policies to protect your personal information during the verification process.',
    },
    {
      icon: <AccessTimeRoundedIcon />,
      title: 'Instant Access',
      description: 'Receive instant access to your certificates upon successful verification, saving you time and effort.',
    },
    {
      icon: <DownloadRoundedIcon />,
      title: 'Easy Download',
      description: 'Download your verified certificates with a single click after completing the verification process.',
    },
  ];
  