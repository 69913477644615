import React, { useState } from 'react';
import {
  Box, Button, Container, Grid, TextField, Typography, MenuItem,
  Stepper, Step, StepLabel, Snackbar, Alert, InputLabel, IconButton, Tooltip
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import axios from 'axios';
import { useSelector } from 'react-redux';

   
const steps = ['Personal Details', 'Academic Qualifications', 'Course Selection', 'Documents Upload', 'Confirmation', 'Success'];

const AddStudent = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    studentId: '',
    firstName: '',
    lastName: '',
    course: '',
    admissionYear: '',
    passYear: '',
    passportPhoto: null,
    passCertificate: null,
    address: '',
    rollNo: '',
    age:''
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);
  const token = useSelector((state) => state.auth.token);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'file' ? files[0] : value
    }));
  };

  const handleSubmit = async() => {
     // Move to the "Success" step

     const data = new FormData();
    data.append('studentId', formData.studentId);
    data.append('firstName', formData.firstName);
    data.append('lastName', formData.lastName);
    data.append('course', formData.course);
    data.append('admissionYear', formData.admissionYear);
    data.append('passYear', formData.passYear);
    data.append('address', formData.address);
    data.append('rollNo', formData.rollNo);
    data.append('age', formData.age);
    if (formData.passportPhoto) data.append('photo', formData.passportPhoto);
    if (formData.passCertificate) data.append('certificate', formData.passCertificate);

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/students/add`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
        }
      });
      setOpenSnackbar(true);
     setActiveStep(steps.length - 1);
      setTimeout(() => {
        // Reset to the first step after 3 seconds
        setActiveStep(0);
        setFormData({
          studentId: '',
          firstName: '',
          lastName: '',
          course: '',
          admissionYear: '',
          passYear: '',
          passportPhoto: null,
          passCertificate: null,
          address: '',
          rollNo: '',
        });
      }, 3000);
    } catch (error) {
      console.error('There was an error submitting the form!', error);
      
    }
  }
  const handleCloseSnackbar = () => setOpenSnackbar(false);

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Registration No"
                variant="outlined"
                name="studentId"
                value={formData.studentId}
                onChange={handleChange}
                InputProps={{
                  style: {
                    backgroundColor: 'white',
                    borderRadius: '8px',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="First Name"
                variant="outlined"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                InputProps={{
                  style: {
                    backgroundColor: 'white',
                    borderRadius: '8px',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Last Name"
                variant="outlined"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                InputProps={{
                  style: {
                    backgroundColor: 'white',
                    borderRadius: '8px',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Address"
                variant="outlined"
                name="address"
                value={formData.address}
                onChange={handleChange}
                InputProps={{
                  style: {
                    backgroundColor: 'white',
                    borderRadius: '8px',
                  },
                }}
              />
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Admission Year"
                variant="outlined"
                name="admissionYear"
                value={formData.admissionYear}
                onChange={handleChange}
                type="number"
                InputProps={{
                  style: {
                    backgroundColor: 'white',
                    borderRadius: '8px',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Pass Year"
                variant="outlined"
                name="passYear"
                value={formData.passYear}
                onChange={handleChange}
                type="number"
                InputProps={{
                  style: {
                    backgroundColor: 'white',
                    borderRadius: '8px',
                  },
                }}
              />
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Select Course"
                variant="outlined"
                name="course"
                type='text'
                value={formData.course}
                onChange={handleChange}
                InputProps={{
                  style: {
                    backgroundColor: 'white',
                    borderRadius: '8px',
                  },
                }}
              >
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Roll Number"
                variant="outlined"
                name="rollNo"
                value={formData.rollNo}
                onChange={handleChange}
                InputProps={{
                  style: {
                    backgroundColor: 'white',
                    borderRadius: '8px',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Age"
                variant="outlined"
                name="age"
                value={formData.age}
                onChange={handleChange}
                InputProps={{
                  style: {
                    backgroundColor: 'white',
                    borderRadius: '8px',
                  },
                }}
              />
            </Grid>
          </Grid>
        );
      case 3:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <InputLabel htmlFor="passportPhoto" sx={{ marginRight: 2 }}>Passport Size Photo</InputLabel>
                <Tooltip title="Upload your passport size photo" arrow>
                  <IconButton
                    color={formData.passportPhoto ? 'success' : 'default'}
                    component="label"
                    sx={{
                      transition: 'color 0.3s',
                      '&:hover': {
                        color: 'green',
                      },
                    }}
                  >
                    <AttachFileIcon />
                    <input
                      type="file"
                      id="passportPhoto"
                      name="passportPhoto"
                      onChange={handleChange}
                      hidden
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <InputLabel htmlFor="passCertificate" sx={{ marginRight: 2 }}>Pass Certificate PDF</InputLabel>
                <Tooltip title="Upload your pass certificate PDF" arrow>
                  <IconButton
                    color={formData.passCertificate ? 'success' : 'default'}
                    component="label"
                    sx={{
                      transition: 'color 0.3s',
                      '&:hover': {
                        color: 'green',
                      },
                    }}
                  >
                    <AttachFileIcon />
                    <input
                      type="file"
                      id="passCertificate"
                      name="passCertificate"
                      onChange={handleChange}
                      hidden
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        );
      case 4:
        return (
          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <Typography variant="h5" gutterBottom>
              Please Confirm Your Details
            </Typography>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} md={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Student ID:
                </Typography>
                <Typography variant="body1">{formData.studentId}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Name:
                </Typography>
                <Typography variant="body1">{formData.firstName} {formData.lastName}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Address:
                </Typography>
                <Typography variant="body1">{formData.address}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Course:
                </Typography>
                <Typography variant="body1">{formData.course}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Admission Year:
                </Typography>
                <Typography variant="body1">{formData.admissionYear}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Pass Year:
                </Typography>
                <Typography variant="body1">{formData.passYear}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Roll Number:
                </Typography>
                <Typography variant="body1">{formData.rollNo}</Typography>
              </Grid>
            </Grid>
          </Box>
        );
      case 5:
        return (
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h4" gutterBottom>
              Your enrollment was successful!
            </Typography>
            <Typography variant="body1">
              Thank you for enrolling in our courses. We have received your details and will process your application shortly.
            </Typography>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Box
      id="hero"
      sx={{
        width: '100%',
        backgroundImage: 'linear-gradient(180deg, #CEE5FD, #FFF)',
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
        overflowX: 'hidden',
        position: 'relative',
      }}
    >
      <Box sx={{ position: 'relative', minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' , mt:'30px' }}>
        <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
            Enrollment successful! You will receive a confirmation message soon.
          </Alert>
        </Snackbar>
        <Container sx={{ zIndex: 1, textAlign: 'center' }}>
          <Typography variant="h4" sx={{ mb: 4, color: 'primary.main', mt: 10 }}>
            Enroll in a Course
          </Typography>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box sx={{ mt: 4 }}>
            {renderStepContent(activeStep)}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
              {activeStep !== 0 && activeStep !== steps.length - 1 && (
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<ArrowBackIcon />}
                  onClick={handleBack}
                >
                  Previous
                </Button>
              )}
              {activeStep === steps.length - 2 ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  endIcon={<ArrowForwardIcon />}
                >
                  Submit
                </Button>
              ) : activeStep !== steps.length - 1 ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  endIcon={<ArrowForwardIcon />}
                >
                  Next
                </Button>
              ) : null}
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default AddStudent;