import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';

export default function CertificateVerificationPanel() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const handleClick = () => {
    setLoading(true);
    setTimeout(() => {
      window.scrollTo(0, 0); // Scroll to the top
      navigate('/verification');
      setLoading(false);
    }, 500);
  };

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          backgroundImage: `url('/images/bg.png')`,
          backgroundAttachment: 'fixed',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          width: '100%',
          height: '100px', // Default height
          borderRadius: 0, // Removed border radius
          mt: '10px', // Added top margin
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          color: '#fff',
          cursor: 'pointer',
          transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          '&:hover': {
            transform: 'scale(1.02)',
            boxShadow: `0 4px 8px ${theme.palette.grey[500]}`,
            '& .default-text': {
              opacity: 0,
            },
            '& .hover-text': {
              opacity: 1,
            },
          },
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: theme.palette.primary.main,
            opacity: 0.7,
            zIndex: 1,
          },
          // Responsive height adjustments
          [theme.breakpoints.down('md')]: {
            height: '80px', // Decreased height for medium screens
          },
          [theme.breakpoints.down('sm')]: {
            height: '60px', // Further decreased height for small screens
          },
        }}
        onClick={handleClick}
      >
        <Box
          sx={{
            position: 'relative',
            zIndex: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            textAlign: 'center',
          }}
        >
          <Typography
            variant="h1"
            component="h1"
            className="default-text"
            sx={{
              fontWeight: 'bold',
              color: '#fff',
              opacity: 1,
              transition: 'opacity 0.3s ease',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 2,
              display: { xs: 'block', md: 'block' },
              fontSize: { xs: '1.2rem', md: '1.8rem', lg: '2.2rem' }, // Responsive text size
              lineHeight: 1.3, // Adjust line height if needed
            }}
          >
            Want To Verify Your Certificate
          </Typography>
          <Typography
            variant="h1"
            component="h2"
            className="hover-text"
            sx={{
              fontWeight: 'bold',
              color: '#d3d3d3',
              opacity: 0,
              transition: 'opacity 0.3s ease',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 2,
              display: { xs: 'block', md: 'block' },
              fontSize: { xs: '1.2rem', md: '2rem', lg: '2.5rem' }, // Responsive text size
              lineHeight: 1.3, // Adjust line height if needed
            }}
          >
            Verify Now
          </Typography>
        </Box>
      </Box>
      {loading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            zIndex: 1200,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
