import React, { useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { keyframes } from '@mui/system';

const CollageGrid = () => {
  const initialImages = [
    require('../images/b.jpg'),
    require('../images/cont.jpg'),
    require('../exprincedtrainer/feildvist.jpeg'),
    require('../images/bvc.jpg'),
    require('../classImages/u.jpg'),
    require('../classImages/vb.jpg'),
    require('../classImages/y.jpg'),
    require('../classImages/xs.jpg'),
  ];

  useEffect(() => {
    const handleScroll = () => {
      const images = document.querySelectorAll('.gallery-image');
      const windowHeight = window.innerHeight;
      const middleY = windowHeight / 2;

      images.forEach(img => {
        const rect = img.getBoundingClientRect();
        const imgCenterY = rect.top + (rect.height / 2);

        if (imgCenterY > middleY - 100 && imgCenterY < middleY + 100) {
          img.classList.add('in-view');
        } else {
          img.classList.remove('in-view');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    handleScroll(); // Initial check

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  const scaleInView = keyframes`
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.1);
    }
  `;

  return (
    <Box sx={{ width: '100%', padding: 0, overflow: 'hidden' }}>
      <Box sx={{ mb: 4, p: 2, mt: 4, backgroundColor: 'primary.light', width: '100%' }}>
        <Typography component="h2" variant="h4" sx={{ color: 'white', textAlign: 'center' }}>
          <span>Our Gallery</span>
        </Typography>
      </Box>
      <Grid container spacing={1} sx={{ height: '100%', paddingLeft: 0, paddingRight: 0 }}>
        {initialImages.map((image, index) => (
          <Grid item xs={6} sm={4} md={3} key={index}>
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                paddingTop: '100%', // Maintains aspect ratio (1:1)
                overflow: 'hidden',
                borderRadius: '8px',
                transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.1)',
                  boxShadow: '0 6px 15px rgba(0, 0, 0, 0.3)',
                },
                '&.in-view': {
                  animation: `${scaleInView} 1s forwards`,
                  border: '2px solid #39a7cc',
                },
                '@media (max-width: 600px)': {
                  paddingTop: '200px', // Adjust height for small screens
                },
              }}
            >
              <img
                src={image}
                alt={`Collage Image ${index + 1}`}
                className="gallery-image"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: '100%',
                  width: '100%',
                  objectFit: 'cover',
                  borderRadius: '8px',
                  transition: 'opacity 1s ease-in-out',
                  
                  opacity: 1,
                }}
                onError={(e) => {
                  e.target.onerror = null; // Prevents infinite loop if fallback fails
                  e.target.src = 'path/to/placeholder-image.png'; // Fallback image
                }}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CollageGrid;
