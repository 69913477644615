import React, { useState, useEffect } from 'react';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { useSelector } from 'react-redux';

const ContactUsFloatingButton = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn); // Get login status from Redux
  const [showButton, setShowButton] = useState(true); // Local state to control button visibility

  useEffect(() => {
    if (isLoggedIn) {
      setShowButton(false); // Hide the button if logged in
    } else {
      setShowButton(true); // Show the button if not logged in
    }
  }, [isLoggedIn]); // Dependency on isLoggedIn

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEmailClick = () => {
    window.location.href = 'mailto:gitimng@gmail.com'; // Use mailto: protocol for email
    handleClose();
  };

  const handleWhatsAppClick = () => {
    // Make sure to use the correct phone number format
    window.open('https://wa.me/918692848115', '_blank'); // Open in a new tab or window
    handleClose();
  };
  

  // If the button should be hidden, return null
  if (!showButton) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: { xs: 16, md: 24 },
        left: { xs: 16, md: 24 },
        zIndex: 1200,
      }}
    >
      <IconButton
        onClick={handleClick}
        sx={{
          backgroundColor: 'primary.main',
          width: { xs: 56, md: 64 },
          height: { xs: 56, md: 64 },
          '&:hover': {
            backgroundColor: 'primary.dark',
          },
        }}
      >
        <ContactSupportIcon
          sx={{
            color: 'white',
            fontSize: { xs: 28, md: 32 },
          }}
        />
      </IconButton>

      {/* Menu for Email and WhatsApp options */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleEmailClick}>
          <EmailIcon sx={{ mr: 1 }} />
          Email Us
        </MenuItem>
        <MenuItem onClick={handleWhatsAppClick}>
          <WhatsAppIcon sx={{ mr: 1 }} />
          WhatsApp
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ContactUsFloatingButton;
