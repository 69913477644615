import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  IconButton,
  Divider,
  CircularProgress,
} from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import { useNavigate } from 'react-router-dom';

export default function FooterT() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const aboutUsRef = useRef(null);
  const [underlineVisible, setUnderlineVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (aboutUsRef.current) {
        const rect = aboutUsRef.current.getBoundingClientRect();
        setUnderlineVisible(rect.top >= 0 && rect.bottom <= window.innerHeight);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleNavigate = (path) => {
    setLoading(true);
    
    // Scroll to the top first
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  
    // Wait for the scroll action to complete
    setTimeout(() => {
      navigate(path); // Navigate to the new route after scrolling is done
      setLoading(false);
    }, 500); // Adjust the delay if needed
  };
  

  const linkStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: '#ffffff',
    textDecoration: 'none',
    fontSize: { xs: '0.75rem', md: '1rem' }, // Adjust font size for mobile
    '&:hover': { color: '#81d4fa', textDecoration: 'underline' },
  };

  return (
    <Box
      component="footer"
      sx={{
        bgcolor: '#212121',
        color: '#ffffff',
        py: 6,
        mt: 8,
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* About Us Section */}
          <Grid item xs={12} md={4} textAlign={{ xs: 'center', md: 'left' }}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                mb: 2,
                position: 'relative',
                color: '#ffffff',
                lineHeight: '2rem',
              }}
              ref={aboutUsRef}
            >
              About Us
              <Divider
                sx={{
                  mt: 2,
                  borderColor: '#81d4fa',
                  height: '2px',
                  width: '50%',
                  mx: 'auto',
                  position: 'absolute',
                  bottom: '-10px',
                  left: '0',
                  right: '0',
                  transform: underlineVisible ? 'scaleX(1)' : 'scaleX(0)',
                  transition: 'transform 0.5s ease-in-out',
                }}
              />
            </Typography>
            <Typography variant="body2" sx={{ lineHeight: 1.8, color: '#e0e0e0' }}>
              Empowering Education for a Brighter Future. Genius Institute delivers quality education 
              and fosters innovation in all its programs.
            </Typography>
          </Grid>

          {/* Social Media and Logo */}
          <Grid item xs={12} md={4} textAlign="center">
            <img
              src={require('../images/banner2.png')}
              alt="Company Logo"
              style={{ width: '150px', marginBottom: '1rem', cursor: 'pointer' , borderRadius:'5px' }}
              onClick={() => handleNavigate('/')}
            />
            <Box display="flex" justifyContent="center" mt={2}>
              {[
                { icon: <InstagramIcon />, href: 'https://instagram.com' },
                { icon: <FacebookIcon />, href: 'https://facebook.com' },
                { icon: <LinkedInIcon />, href: 'https://linkedin.com' },
                { icon: <PhoneIcon />, href: 'tel:+91 8692848115' },
                { icon: <EmailIcon />, href: 'mailto:gitimng@gmail.com' },
              ].map(({ icon, href }, index) => (
                <IconButton
                  key={index}
                  sx={{ color: '#ffffff', mx: 1, '&:hover': { color: '#81d4fa' } }}
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {icon}
                </IconButton>
              ))}
            </Box>
          </Grid>

          {/* Quick Links */}
          <Grid item xs={12} md={4}>
            <Grid container spacing={2}>
              {/* Left Column Links */}
              <Grid item xs={6} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="flex-start" mb={{ xs: 2, md: 0 }}>
                  <Link onClick={() => handleNavigate('/about')} sx={linkStyles} mb={2}>
                    About Us
                  </Link>
                  <Link onClick={() => handleNavigate('/about')} sx={linkStyles} mb={2}>
                    Director Message
                  </Link>
                  <Link onClick={() => handleNavigate('/')} sx={linkStyles} mb={2}>
                    Home
                  </Link>
                </Box>
              </Grid>

              {/* Right Column Links */}
              <Grid item xs={6} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                  <Link onClick={() => handleNavigate('/verification')} sx={linkStyles} mb={2}>
                    Verification
                  </Link>
                  <Link onClick={() => handleNavigate('/enroll')} sx={linkStyles} mb={2}>
                    Enroll Now
                  </Link>
                  <Link onClick={() => handleNavigate('/contactus')} sx={linkStyles} mb={2}>
                    Contact Us
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider sx={{ my: 4, borderColor: '#1565c0' }} />

        {/* Copyright */}
        <Box textAlign="center">
          <Typography variant="body2" sx={{ color: '#e0e0e0' }}>
            Empowering Education for a Brighter Future
          </Typography>
          <Typography variant="body2" sx={{ mt: 2, color: '#e0e0e0' }}>
            &copy; 2024 Genius Institute &nbsp; <span style={{ fontSize: '1.2rem' }}>∞</span> &nbsp; Made by Infinite Corporation
          </Typography>
        </Box>

        {/* Loading Spinner */}
        {loading && (
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              zIndex: 1200,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Container>
    </Box>
  );
}
