import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import { useDispatch } from 'react-redux';
import { logout } from '../Slice/AdminSlice';

const logoStyle = {
  width: '140px',
  height: 'auto',
  cursor: 'pointer',
};

function AdminAppBar() {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch()
  const logo = require('../../images/banner2.png')

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleNavigate = (path) => {
    setOpen(false);
    setLoading(true);
    setTimeout(() => {
      window.scrollTo(0, 0); // Scroll to the top when navigating
      navigate(path);
      setLoading(false);
    }, 500);
  };

  const handleLogout = () => {
    // Implement logout functionality here
    console.log("Logging out...");
    dispatch(logout())
    navigate('/'); // Redirect to login page after logout
  };

  const getButtonStyle = (path) => ({
    textTransform: 'none',
    bgcolor: location.pathname === path ? 'action.selected' : 'transparent',
    color: location.pathname === path ? 'primary.main' : 'text.primary',
    fontSize: '1.1rem', // Slightly increase text size
    '&:hover': {
      bgcolor: 'lightblue',
    },
  });

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          bgcolor: 'white',
          boxShadow: 1,
          width: '100%',
          top: 0,
          left: 0,
          right: 0,
          zIndex: (theme) => theme.zIndex.appBar,
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 80,
            px: 2,
          }}
        >
          {/* Logo on the Left */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={logo}
              style={logoStyle}
              alt="logo"
              onClick={() => handleNavigate('/getStudent')}
            />
          </Box>

          {/* Desktop Menu */}
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              flexGrow: 1,
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Button onClick={() => handleNavigate('/getStudent')} sx={getButtonStyle('/getStudent')}>Dashboard</Button>
            <Button onClick={() => handleNavigate('/addStudent')} sx={getButtonStyle('/addStudent')}>Add Student</Button>
            <Button onClick={() => handleNavigate('/enrollStudent')} sx={getButtonStyle('/enrollStudent')}>Enroll Student</Button>
          </Box>

          {/* Logout Button */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
            <Button
              color="primary"
              variant="outlined"
              onClick={handleLogout}
              sx={{
                textTransform: 'none',
                fontSize: '1rem',
                borderColor: 'primary.main',
                color: 'primary.main',
                '&:hover': {
                  borderColor: 'primary.dark',
                  color: 'primary.dark',
                  bgcolor: 'rgba(0, 0, 0, 0.08)',
                },
                ml: 2,
              }}
            >
              <LogoutIcon sx={{ mr: 1 }} />
              Logout
            </Button>
          </Box>

          {/* Mobile Drawer Toggle */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Button
              variant="text"
              color="primary"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{ minWidth: '40px', display: { xs: 'flex', md: 'none' } }}
            >
              <MenuIcon />
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Mobile Drawer */}
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            width: 250,
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <MenuItem onClick={() => handleNavigate('/getStudent')}>Dashboard</MenuItem>
            <MenuItem onClick={() => handleNavigate('/addStudent')}>Add Student</MenuItem>
            <MenuItem onClick={() => handleNavigate('/enrollStudent')}>Enroll Student</MenuItem>
          </Box>
          <Divider />
          <MenuItem>
            <Button
              color="primary"
              variant="outlined"
              onClick={handleLogout}
              sx={{ width: '100%' }}
            >
              <LogoutIcon sx={{ mr: 1 }} />
              Logout
            </Button>
          </MenuItem>
        </Box>
      </Drawer>

      {/* Loading Spinner */}
      {loading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            zIndex: 1200,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}



export default AdminAppBar;
