import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Vido.css';

const CarouselVideo = () => {
  const slides = [
    {
      video: require('../../Videos/banq.mp4'),
    },
    {
      video: require('../../Videos/course.mp4'),
    },
    {
      video: require('../../Videos/ban.mp4'),
    },
    {
      video: require('../../Videos/afor.mp4'),
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const slideInterval = setInterval(nextSlide, 5000); // Automatically slide every 5 seconds
    return () => clearInterval(slideInterval);
  }, [currentSlide]);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === slides.length - 1 ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    );
  };

  const handleSlideClick = () => {
    navigate('/enroll');
    window.scrollTo(0, 0);
  };

  return (
    <div className="carousel">
      <div className="carousel-inner">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={
              index === currentSlide
                ? 'carousel-item active'
                : 'carousel-item'
            }
            onClick={handleSlideClick}
          >
            {index === currentSlide ? (
              // Preload the current video to make it visible immediately
              <video
                src={slide.video}
                autoPlay
                muted
                loop
                preload="auto" // Preload the active video
              />
            ) : (
              // Use loading="lazy" for other videos
              <video
                src={slide.video}
                muted
                loop
                loading="lazy" // Lazy load non-active videos
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CarouselVideo;
