import Box from '@mui/material/Box';
import DynamicSelectedGrid from './DisplayDynamicComponent/DynamicSelectedGrid';
import OurPlacement from './Placement';
import { courses, Facilities, HighlightsArray } from './HeroArrays';
import DynamicHighlights from './DisplayDynamicComponent/DynamicHighlights';
import NoticePanel from './coursel/Notice';
import AboutUsPanel from './coursel/Abouthero';
import CertificateVerificationPanel from './coursel/Verifypanel';
import FooterT from './DownFooter';
import CourseCarousel from './coursel/CoursesCoursel';
import CollageGrid from './collaage';
import MapComponent from './Map';
import CarouselVideo from './coursel/Vid';
import Testimonials from './coursel/testimonial';



const Hero = () => {

 

  return (
    <Box
      id="hero"
      sx={{
        width: '100%',
        backgroundImage:
           'linear-gradient(180deg, #CEE5FD, #FFF)',
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
        overflowX: 'hidden',
        position: 'relative',
      }}
    >
      
      <CarouselVideo/>
      
      
      <NoticePanel/>
      <CertificateVerificationPanel/>
      <AboutUsPanel/>
      <CourseCarousel/>
      <DynamicSelectedGrid facilities={Facilities} name={'Facilities'} />
      <DynamicSelectedGrid facilities={courses} name={'Top Courses'} />
      <OurPlacement />
      <Testimonials/>
      <CollageGrid/>
      <DynamicHighlights
        title={'Highlights'}
        description={'Discover the key features and benefits of our institution. From top certifications to flexible timings and open admissions, explore what sets us apart.'}
        highlights={HighlightsArray}
      />
       
      <MapComponent/>
     
      
      <FooterT/>
      
    </Box>
  );
};

export default Hero;
