import React, { useState, useEffect } from 'react';
import { Box, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, CircularProgress, TextField, Pagination } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { useSelector } from 'react-redux';

const TableHeaderCell = styled(TableCell)({
  backgroundColor: '#0056b3',
  color: '#fff',
  fontWeight: 'bold',
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#f5f5f5',
    cursor: 'pointer',
  },
}));

const SearchEnroolStudent = () => {
  const [students, setStudents] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const studentsPerPage = 10;
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    setLoading(true);
    fetchStudentData();
  }, []);

  const fetchStudentData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/enrool/getAll`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      if (response.data) {
        setStudents(response.data);
      }
    } catch (error) {
      console.error("Error fetching the student data!", error);
    }
    setLoading(false);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const filteredStudents = students.filter((student) => {
    const searchLower = searchQuery.toLowerCase();
    return (
      student.name.toLowerCase().includes(searchLower) ||
      student.email.toLowerCase().includes(searchLower) ||
      student.phone.toLowerCase().includes(searchLower) ||
      student.course.toLowerCase().includes(searchLower)
    );
  });

  const currentStudents = filteredStudents.slice((page - 1) * studentsPerPage, page * studentsPerPage);

  return (
    <Box sx={{ width: '100%', backgroundImage: 'linear-gradient(180deg, #CEE5FD, #FFF)', py: 20 }}>
      <Container>
        <Typography variant="h4" sx={{ mb: 2, textAlign: 'center', color: '#0056b3' }}>
          Enrolled Students
        </Typography>

        <TextField
          label="Search by Name, Email, Phone, or Course"
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ mb: 3 }}
        />

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <TableContainer sx={{ border: '1px solid #e0e0e0', borderRadius: '8px' }}>
              <Table sx={{ minWidth: 600 }}>
                <TableHead>
                  <TableRow>
                    <TableHeaderCell>Serial No.</TableHeaderCell>
                    <TableHeaderCell>Student Name</TableHeaderCell>
                    <TableHeaderCell>Email</TableHeaderCell>
                    <TableHeaderCell>Phone Number</TableHeaderCell>
                    <TableHeaderCell>Course</TableHeaderCell>
                    <TableHeaderCell>Enroll Date</TableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredStudents.length > 0 ? (
                    currentStudents.map((student, index) => (
                      <StyledTableRow key={student.id}>
                        <TableCell>{(page - 1) * studentsPerPage + index + 1}</TableCell>
                        <TableCell>{student.name}</TableCell>
                        <TableCell>{student.email}</TableCell>
                        <TableCell>{student.phone}</TableCell>
                        <TableCell>{student.course}</TableCell>
                        <TableCell>{student.enroolDate}</TableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        No Students Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Pagination
                count={Math.ceil(filteredStudents.length / studentsPerPage)}
                page={page}
                onChange={handlePageChange}
                color="primary"
              />
            </Box>
          </>
        )}
      </Container>
    </Box>
  );
};

export default SearchEnroolStudent;
