import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DynamicHighlights from '../DisplayDynamicComponent/DynamicHighlights';
import FooterT from '../DownFooter';
import { HighlightsArray } from '../HeroArrays';
import MapComponent from '../Map';

const contactUsArray = [
  {
    icon: <EmailIcon fontSize="large" />,
    title: 'Email Us',
    description: 'Reach out to us at gitimng@gmail for any queries or support.',
    link: 'mailto:gitimng@gmail.com',  // Changed to mailto:
  },
  {
    icon: <PhoneIcon fontSize="large" />,
    title: 'Call Us',
    description: 'Call us at +91 8692848115 for immediate assistance.',
    link: 'tel:+91 8692848115',  // tel: for phone links
  },
  {
    icon: <LocationOnIcon fontSize="large" />,
    title: 'Visit Us',
    description: 'Find us at: Jorapur Jawahar Nagar Raipur Chhattisgarh',
    link: 'https://www.google.com/maps?ll=21.242852,81.631823&z=18&t=m&hl=en&gl=IN&mapclient=embed&cid=9907480809183839929',  // Maps URL for location
  },
];

const ContactUs = () => {


  return (
    <Box
      id="hero"
      sx={{
        width: '100%',
        backgroundImage: 'linear-gradient(180deg, #CEE5FD, #FFF)',
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
        overflowX: 'hidden',
        position: 'relative',
      }}
    >
      <Container sx={{ py: { xs: 10, sm: 16 }, textAlign: 'center' }}>
        <Typography
          variant="h2"
          sx={{ fontSize: { xs: '2rem', sm: '2.5rem' }, mb: 4, mt: 4 }}
        >
          Contact Us
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ mb: { xs: 4, sm: 8 }, px: { xs: 2, sm: 4 } }}
        >
          We’re here to help you! Whether you have questions, need assistance, or just want to say hello, feel free to reach out using the methods below.
        </Typography>

        <Grid container spacing={4}>
          {contactUsArray.map(({ icon, title, description, link }, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <a href={link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                <Card
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    borderRadius: 2,
                    boxShadow: '#CEE5FD',
                    backgroundColor: '#A7C7E7',
                    p: 2,
                    border: `1px solid transparent`,
                    '&:hover': {
                      boxShadow: '#CEE5FD',
                      transform: 'scale(1.05)',
                      transition: '0.3s',
                      borderColor: '#CEE5FD',
                    },
                  }}
                >
                  <Box sx={{ mb: 2 }}>{icon}</Box>
                  <CardContent>
                    <Typography variant="h6" color="text.primary">
                      {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {description}
                    </Typography>
                  </CardContent>
                </Card>
              </a>
            </Grid>
          ))}
        </Grid>
      </Container>
      <MapComponent />
      <DynamicHighlights
        title={'Highlights'}
        description={'Discover the key features and benefits of our institution. From top certifications to flexible timings and open admissions, explore what sets us apart.'}
        highlights={HighlightsArray}
      />
      <FooterT />
    </Box>
  );
};

export default ContactUs;
