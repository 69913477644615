import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function DynamicHighlights({ title, description, highlights }) {
  React.useEffect(() => {
    const handleScroll = () => {
      const cards = document.querySelectorAll('.highlight-card');
      const windowHeight = window.innerHeight;
      const middleY = windowHeight / 2;
      const isLargeScreen = window.matchMedia('(min-width: 1200px)').matches; // Check for large screen

      if (!isLargeScreen) {  // Disable auto-hover on large screens
        cards.forEach((card) => {
          const rect = card.getBoundingClientRect();
          const cardCenterY = rect.top + rect.height / 2;

          if (cardCenterY > middleY - 100 && cardCenterY < middleY + 100) {
            card.classList.add('centered');
          } else {
            card.classList.remove('centered');
          }
        });
      } else {
        // Ensure cards are not affected when on large screens
        cards.forEach((card) => card.classList.remove('centered'));
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    handleScroll(); // Initial check

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  return (
    <>
      <Box sx={{ mb: 4, p: 2, mt: 4, backgroundColor: 'primary.light', width: '100%' }}>
        <Typography component="h2" variant="h4" sx={{ color: 'white', textAlign: 'center' }}>
          <span>Why We Are Best</span>
        </Typography>
      </Box>
      <Box
        id="highlights"
        sx={{
          pt: { xs: 3, sm: 5 },
          pb: { xs: 2, sm: 2 },
          color: 'white',
          bgcolor: 'white',
        }}
      >
        <Container
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: { xs: 3, sm: 4 },
            maxWidth: { xs: '100%', lg: '90%' },
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: 'black',
              textAlign: 'center',
              marginTop: '2px',
            }}
          >
            {description}
          </Typography>
          <Grid container spacing={2.5}>
            {highlights.map((item, index) => (
              <Grid item xs={12} sm={6} md={6} mb={6} key={index}>
                <Stack
                  direction="column"
                  color="inherit"
                  component={Card}
                  className="highlight-card"
                  spacing={1}
                  useFlexGap
                  sx={{
                    p: 3,
                    height: '100%',
                    border: '1px solid',
                    borderColor: '#004E98',
                    backgroundColor: '#bcd4e6',
                    transition: 'transform 0.3s, box-shadow 0.3s',
                    '&:hover, &.centered': {
                      transform: 'scale(1.05)',
                      boxShadow: '0 4px 20px rgba(3, 51, 99, 0.5)',
                      backgroundColor: '#35628A',
                    },
                  }}
                >
                  <Box sx={{ opacity: '70%', color: 'black' }}>{item.icon}</Box>
                  <div>
                    <Typography fontWeight="medium" gutterBottom sx={{ color: 'black' }}>
                      {item.title}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'black' }}>
                      {item.description}
                    </Typography>
                  </div>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </>
  );
}
