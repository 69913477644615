// src/components/AdminRoute.js
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import AdminAppBar from './Components/admin/AdminNavbar';


const AdminRoute = () => {
  const { token } = useSelector((state) => state.auth);

  return token ? (
    <>
      <AdminAppBar />
      {/* Render the admin navbar */}
      <main> {/* Adjust marginTop if needed */}
        <Outlet /> {/* Render the nested routes */}
      </main>
    </>

  ) : (
    <Navigate to="/adminLogin" replace />
  )

};

export default AdminRoute;
