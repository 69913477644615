// store.js
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './AdminSlice' // Import your auth slice or any other slices

export const store = configureStore({
  reducer: {
    auth: authReducer, // Add more reducers if needed
  },
});

export default store;
