import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import SignInIcon from '@mui/icons-material/Lock';
import HomeIcon from '@mui/icons-material/Home';
import VerificationIcon from '@mui/icons-material/VerifiedUser';
import SchoolIcon from '@mui/icons-material/School';
import CastForEducationIcon from '@mui/icons-material/CastForEducation'; // Import the new icon
import ContactMailIcon from '@mui/icons-material/ContactMail';
import InfoIcon from '@mui/icons-material/Info';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import ContactUsFloatingButton from './ContactusFloating';

const logoStyle = {
  width: '210px',
  height: 'auto',
  cursor: 'pointer',
};

function AppAppBar() {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const logo = require('./images/banner2.png');

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleNavigate = (path) => {
    setOpen(false);
    setLoading(true);
    setTimeout(() => {
      window.scrollTo(0, 0); // Scroll to the top when navigating
      navigate(path);
      setLoading(false);
    }, 100);
  };

  const getButtonStyle = (path) => ({
    textTransform: 'none',
    bgcolor: location.pathname === path ? '#B6D0E2' : '#35628A',
    color:  '#FFFFFF',
    // borderColor:  '#35628A', // Border color: colored when selected, transparent otherwise

    // border: '2px solid', // Always show a border

    fontSize: '1.1rem', // Slightly increase text size
    '&:hover': {
      bgcolor: 'lightblue', // Change hover color to light blue
      transform: 'scale(1.1)', // Slightly enlarge the button on hover

    },
  });

  const iconStyle = { color: '#FFFFFF' }; // Define the icon color
  const iconStyles = { color: '#35628A' }; // Define the icon color


  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          bgcolor: 'white',
          boxShadow: 1,
          width: '100%',
          top: 0,
          left: 0,
          right: 0,
          zIndex: (theme) => theme.zIndex.appBar,
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 80,
            px: 2,
          }}
        >
          {/* Logo on the Left */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={logo}
              style={logoStyle}
              alt="logo"
              onClick={() => handleNavigate('/')}
            />
          </Box>

          {/* Desktop Menu */}
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              flexGrow: 1,
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Button onClick={() => handleNavigate('/')} sx={getButtonStyle('/')}>
              <HomeIcon sx={{ ...iconStyle, mr: 1 }} />
              Home
            </Button>
            <Button onClick={() => handleNavigate('/verification')} sx={getButtonStyle('/verification')}>
              <VerificationIcon sx={{ ...iconStyle, mr: 1 }} />
              Verification
            </Button>
            <Button onClick={() => handleNavigate('/enroll')} sx={getButtonStyle('/enroll')}>
              <CastForEducationIcon sx={{ ...iconStyle, mr: 1 }} /> {/* Updated icon */}
              Enroll
            </Button>
            <Button onClick={() => handleNavigate('/courses')} sx={getButtonStyle('/courses')}>
              <SchoolIcon sx={{ ...iconStyle, mr: 1 }} /> {/* Updated icon */}
              Courses
            </Button>
            <Button onClick={() => handleNavigate('/contactus')} sx={getButtonStyle('/contactus')}>
              <ContactMailIcon sx={{ ...iconStyle, mr: 1 }} />
              Contact Us
            </Button>
            <Button onClick={() => handleNavigate('/about')} sx={getButtonStyle('/about')}>
              <InfoIcon sx={{ ...iconStyle, mr: 1 }} />
              About
            </Button>
          </Box>

          {/* Sign In Button for Desktop */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => handleNavigate('/adminLogin')}
              sx={{
                textTransform: 'none',
                fontSize: '1rem',
                borderColor: 'primary.main',
                color: 'primary.main',
                '&:hover': {
                  borderColor: 'primary.dark',
                  color: 'primary.dark',
                  bgcolor: 'rgba(0, 0, 0, 0.08)',
                },
                ml: 2,
              }}
            >
              <SignInIcon sx={{ ...iconStyles, mr: 1 }} />
              Sign In
            </Button>
          </Box>

          {/* Mobile Drawer Toggle */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Button
              variant="text"
              color="primary"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{ minWidth: '40px', display: { xs: 'flex', md: 'none' } }}
            >
              <MenuIcon sx={iconStyles} />
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Mobile Drawer */}
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            width: 250,
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <MenuItem onClick={() => handleNavigate('/')}>
              <HomeIcon sx={{ ...iconStyles, mr: 1 }} />
              Home
            </MenuItem>
            <MenuItem onClick={() => handleNavigate('/verification')}>
              <VerificationIcon sx={{ ...iconStyles, mr: 1 }} />
              Verification
            </MenuItem>
            <MenuItem onClick={() => handleNavigate('/enroll')}>
              <CastForEducationIcon sx={{ ...iconStyles, mr: 1 }} /> {/* Updated icon */}
              Enroll
            </MenuItem>
            <MenuItem onClick={() => handleNavigate('/courses')}>
              <SchoolIcon sx={{ ...iconStyles, mr: 1 }} /> {/* Updated icon */}
              Courses
            </MenuItem>
            <MenuItem onClick={() => handleNavigate('/contactus')}>
              <ContactMailIcon sx={{ ...iconStyles, mr: 1 }} />
              Contact Us
            </MenuItem>
            <MenuItem onClick={() => handleNavigate('/about')}>
              <InfoIcon sx={{ ...iconStyles, mr: 1 }} />
              About
            </MenuItem>
          </Box>
          <Divider />
          <MenuItem>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => handleNavigate('/adminLogin')}
              sx={{ width: '100%' }}
            >
              <SignInIcon sx={{ ...iconStyles, mr: 1 }} />
              Sign In
            </Button>
          </MenuItem>
        </Box>
      </Drawer>

      {/* Loading Spinner */}
      {loading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            zIndex: 1200,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <ContactUsFloatingButton />
    </div>
  );
}

export default AppAppBar;
