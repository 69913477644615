import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { blue, grey } from '@mui/material/colors';

const MarqueeWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  padding: '10px 0',
  marginTop: '0px',
  backgroundColor: '#35628A',
  width: '100vw',
  position: 'relative',
  left: 'calc(-50vw + 50%)',
}));

const MarqueeText = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  padding: '0 30px',
  fontSize: 'clamp(0.9rem, 1.2vw, 1.25rem)', // Responsive font size for better scaling
  borderLeft: `5px solid ${blue[500]}`,
  paddingLeft: '10px',
  animation: `scroll 15s linear infinite`, // Adjust speed based on text length
  color: '#FFFFFF', // Changed text color to white
  '@keyframes scroll': {
    '0%': {
      transform: 'translateX(100%)',
    },
    '100%': {
      transform: 'translateX(-100%)',
    },
  },
  [theme.breakpoints.down('sm')]: {
    animationDuration: '25s', // Slower scrolling on smaller screens for better readability
    fontSize: 'clamp(0.8rem, 1.1vw, 1rem)', // Smaller font size for small screens
  },
}));

const NoticePanel = () => {
  return (
    <Container sx={{ marginTop: 0, padding: 0, textAlign: 'center' }}>
      <MarqueeWrapper>
        <MarqueeText>
          BSL PACC EXAMS ARE CONDUCTED IN OUR ORGANISATION. TO KNOW MORE KINDLY CONTACT OUR OPERATION MANAGER CONT: +91 8692848115
        </MarqueeText>
        <MarqueeText>
          WE ARE GOING TO DO LOTTERY FOR 10 STUDENTS RS.5000 FLAT DISCOUNT ON ANY COURSE. TO GET THIS VISIT OUR OFFICE.
        </MarqueeText>
        <MarqueeText>
          विदेश जाने के इच्छुक अभ्यर्थी संपर्क करें!
        </MarqueeText>
      </MarqueeWrapper>
    </Container>
  );
};

export default NoticePanel;
