import React, { useState } from 'react';
import { Box, Button, Container, Grid, TextField, Typography, Card, CardContent, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';  // Import the visibility icons
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { loginSuccess } from '../Slice/AdminSlice';
import { useDispatch } from 'react-redux';

const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);  // State to manage password visibility
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = async () => {
    setLoading(true);
    setErrorMessage('');

    if (!username || !password) {
      setErrorMessage('Please fill in all required fields.');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/authenticate`, {
        username,
        password,
      });

      const token = response.data.jwtToken;
      // const userInformation = response.data.userInformation; // If your backend sends user info
      // Save the token and user data in Redux
      dispatch(loginSuccess({ token }));

      // Redirect to the home page or dashboard
      navigate('/getStudent');
    } catch (error) {
      setErrorMessage(error.response ? 'Invalid username or password.' : 'Server not found. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      id="hero"
      sx={{
        width: '100%',
        backgroundImage: 'linear-gradient(180deg, #CEE5FD, #FFF)',
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
        overflowX: 'hidden',
        position: 'relative',
        py: { xs: 30, sm: 30 }
      }}
    >
      <Container maxWidth="sm">
        <Card sx={{ boxShadow: 3 }}>
          <CardContent>
            <Typography variant="h4" textAlign="center" gutterBottom>
              Login
            </Typography>
            <Typography variant="body2" color="textSecondary" textAlign="center" mb={3}>
              Enter your credentials to sign in
            </Typography>
            {errorMessage && (
              <Typography variant="body2" color="error" textAlign="center" mb={2}>
                {errorMessage}
              </Typography>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  label="Username"
                  variant="outlined"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  InputProps={{
                    style: {
                      backgroundColor: 'white',
                      borderRadius: '8px',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  label="Password"
                  type={showPassword ? 'text' : 'password'}  // Toggle between password and text
                  variant="outlined"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    style: {
                      backgroundColor: 'white',
                      borderRadius: '8px',
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleLogin}
                  disabled={loading}
                  sx={{
                    height: '56px',
                    fontSize: { xs: '16px', sm: '18px' },
                  }}
                >
                  {loading ? 'Logging in...' : 'Login'}
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default LoginForm;
