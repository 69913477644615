// src/Slice/AdminSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: null,
  isLoggedIn: false, // Add this to track login status
};

const adminSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.token = action.payload.token;
      state.isLoggedIn = true; // Set to true when login is successful
    },
    logout: (state) => {

      state.token = null;
      state.isLoggedIn = false; // Reset to false on logout
    },
  },
});

export const { loginSuccess, logout } = adminSlice.actions;
export default adminSlice.reducer;
