import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Card,
  CircularProgress,
} from '@mui/material';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AdminStudentDetail = () => {
  const { studentId } = useParams();
  const [studentData, setStudentData] = useState(null);
  const [passportPhotoUrl, setPassportPhotoUrl] = useState(null);
  const [passportPhotoFile, setPassportPhotoFile] = useState(null);
  const [certificateFile, setCertificateFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    if (studentId) {
      fetchStudentData(studentId);
    }
  }, [studentId]);

  const fetchStudentData = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/students/${id}`,{
      });
      if (response.data) {
        setStudentData(response.data);
        fetchPassportPhoto(id);
      }
    } catch (error) {
      console.error('Error fetching the student data!', error);
    }
    setLoading(false);
  };

  const fetchPassportPhoto = async (id) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/students/photo/${id}`, {
        responseType: 'blob',
      
      });
      const photoUrl = URL.createObjectURL(response.data);
      setPassportPhotoUrl(photoUrl);
    } catch (error) {
      console.error('Error fetching the passport photo:', error);
    }
  };

  const handleInputChange = (e) => {
    setStudentData({
      ...studentData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    setPassportPhotoFile(file);
    setPassportPhotoUrl(URL.createObjectURL(file));
  };

  const handleCertificateUpload = (e) => {
    setCertificateFile(e.target.files[0]);
  };

  const handleUpdateDetails = async () => {
    const data = new FormData();
    data.append('studentId', studentData.studentId);
    data.append('firstName', studentData.firstName);
    data.append('lastName', studentData.lastName);
    data.append('course', studentData.course);
    data.append('admissionYear', studentData.admissionYear);
    data.append('passYear', studentData.passYear);
    data.append('address', studentData.address);
    data.append('rollNo', studentData.rollno);
    data.append('age', studentData.age);
    try {
      setLoading(true);
      await axios.put(`${process.env.REACT_APP_API_URL}/students/update`, data,{
                  headers: {
                    Authorization: `Bearer ${token}`,
                  }
                
      });
      setLoading(false);
      setIsDialogOpen(false)
      navigate(`/student/${studentId}`);

    } catch (error) {
      console.error('Error updating the student details!', error);
      setLoading(false);
    }
  };

  const handleUpdatePhoto = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('photo', passportPhotoFile);

      await axios.put(`${process.env.REACT_APP_API_URL}/students/updatePhoto/${studentId}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' ,
                   Authorization: `Bearer ${token}`,
      },
      });

      setLoading(false);
      navigate(`/student/${studentId}`);
      
    } catch (error) {
      console.error('Error updating the photo!', error);
      setLoading(false);
    }
  };

  const handleUpdateCertificate = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('certificate', certificateFile);

      await axios.put(`${process.env.REACT_APP_API_URL}/students/updateCertificate/${studentId}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
         },
          
      
      });
      setLoading(false);
      navigate(`/student/${studentId}`);
      
    } catch (error) {
      console.error('Error updating the certificate!', error);
      setLoading(false);
    }
  };

  const handleDownloadCertificate = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/students/download-certificate/${studentId}`, {
        responseType: 'blob',      
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'certificate.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error downloading the certificate!', error);
    }
  };

  return (
    <Box sx={{ width: '100%', minHeight: '100vh', py: 5 }}>
      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
          <CircularProgress />
        </Box>
      )}
      {!loading && studentData && (
        <Container sx={{ zIndex: 1 }}>
          <Typography variant="h4" sx={{ mb: 4, textAlign: 'center', color: 'primary.main' }}>
            Student Details
          </Typography>
          <Grid container spacing={4}>
            {/* Photo and Certificate Section */}
            <Grid item xs={12} sm={6}>
              <Card sx={{ padding: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', bgcolor: 'background.paper', boxShadow: 2 }}>
                {passportPhotoUrl && (
                  <Box component="img" src={passportPhotoUrl} alt="Passport Size Photo" sx={{ width: 150, height: 150, borderRadius: '50%', border: '4px solid white', mb: 3 }} />
                )}
                <Button variant="contained" color="primary" component="label" sx={{ mb: 2 }}>
                  {passportPhotoFile ? 'Photo Selected' : 'Update Photo'}
                  <input accept="image/*" type="file" onChange={handlePhotoChange} hidden />
                </Button>
                <Button variant="contained" color="primary" component="label" sx={{ mb: 2 }}>
                  {certificateFile ? 'Certificate Selected' : 'Update Certificate'}
                  <input accept=".pdf" type="file" onChange={handleCertificateUpload} hidden />
                </Button>
                <Button variant="contained" color="secondary" sx={{ mt: 2 }} onClick={handleUpdatePhoto}>
                  Save Photo
                </Button>
                <Button variant="contained" color="secondary" sx={{ mt: 2 }} onClick={handleUpdateCertificate}>
                  Save Certificate
                </Button>
                <Button variant="contained" color="primary" sx={{ mt: 3 }} onClick={handleDownloadCertificate}>
                  Download Certificate
                </Button>
              </Card>
            </Grid>

            {/* Student Details Section */}
            <Grid item xs={12} sm={6}>
              <Grid container spacing={3}>
                {[
                  { label: 'First Name', name: 'firstName' },
                  { label: 'Last Name', name: 'lastName' },
                  { label: 'Age', name: 'age' },
                  { label: 'Admission Year', name: 'admissionYear' },
                  { label: 'Pass Year', name: 'passYear' },
                  { label: 'Course', name: 'course' },
                  { label: 'Address', name: 'address' },
                  { label: 'Roll No', name: 'rollno' },
                ].map((field) => (
                  <Grid item xs={12} key={field.name}>
                    <TextField fullWidth label={field.label} variant="outlined" name={field.name} value={studentData[field.name] || ''} onChange={handleInputChange} required />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 4 }}>
            <Grid item xs={12} sm={6}>
              <Button fullWidth variant="contained" color="primary" onClick={() => setIsDialogOpen(true)}>
                Update Details
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button fullWidth variant="contained" color="secondary" onClick={() => navigate('/getStudent')}>
                Cancel
              </Button>
            </Grid>
          </Grid>

          {/* Confirmation Dialog */}
          <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
            <DialogTitle>Update Student Details</DialogTitle>
            <DialogContent>
              <Typography>Are you sure you want to update the student details?</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsDialogOpen(false)}>Cancel</Button>
              <Button onClick={handleUpdateDetails} color="primary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      )}
    </Box>
  );
};

export default AdminStudentDetail;
